.rt-th.rt-resizable-header {
  width: 160px !important;
}

.rt-tbody .rt-td {
  width: 160px !important;
}

.rt-tbody {
  overflow: visible !important;
}

.code-editor {
  font-family: 'Courier New', Courier, monospace;
  font-size: 16px;
  width: 100%;
  min-height: 200px;
  border: 1px solid #ccc;
  padding: 10px;
}

.select-options {
  position: absolute;
  top: 13px;
  left: 400px;
  height: 200px;
  padding: 10px;
  border: 1px solid #ccc;
  background: white;
  width: 200px;
  overflow: scroll;
}

.select-option {
  cursor: pointer;
  padding: 5px;
}

.select-option:hover {
  background-color: #deebff;
}

.vql-options {
  height: 300px;
  overflow-y: auto;
  background: #eeeeee;
  padding: 12px 20px
}